import styled from "styled-components"
import Typography from "@/components/styled/Typography"
import { SectionContainer } from "@/components/styled/Grid"
import Slider from "@/components/Slider"
import Testimonial from "./Testimonial"

const Background = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
`
const Wrapper = styled(SectionContainer)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  && {
    overflow: visible;
  }
  .slick-dots {
    display: flex !important;
    width: auto;
    margin: 0 auto;
    justify-content: center;
    li {
      display: inline-block;
      margin: 0 10px;
    }
  }
`

export default function Testimonials({ TESTIMONIALS }) {
  const testimonialsList = Object.keys(TESTIMONIALS).map(key => (
    <Testimonial key={key} testimonial={TESTIMONIALS[key]} />
  ))

  return (
    <Background>
      <Wrapper>
        <Typography variant="title2" $weight="medium" className="mb-5">
          Qué opinan nuestros pacientes
        </Typography>
        <Slider
          sliderSettings={{
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
            dots: true,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          }}
        >
          {testimonialsList}
        </Slider>
      </Wrapper>
    </Background>
  )
}
